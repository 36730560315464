
import React from 'react'; 
import OrionLayout from '../../core/layouts/orion-layout/orion-layout';
import './home.scss';
import HomeAboutUs from './home-about-us';
import Sectors from "../sectors/sectors";
import Projects from "../projects/projects";
import Clients from "../clients/clients";
import Careers from '../careers/careers';
import Gallery from '../gallery/gallery';
import NewsAndBlog from '../news-blogs/news-blogs';
import SectorsMain from '../sectors-main/sectors-main';
import CarouselComponent from '../banner-carousel/banner-carousel';
import WhyOrion from '../why-orion/why-orion';
export default class OrionHome extends React.Component {
    
    render(){
        return (
            <OrionLayout>
            <CarouselComponent></CarouselComponent>
            <HomeAboutUs></HomeAboutUs>
            <SectorsMain></SectorsMain>
            <Sectors></Sectors>
            <Projects comingFrom={'home-page'}></Projects>
            <Clients></Clients>
            <WhyOrion></WhyOrion>
            <Careers></Careers>
            <NewsAndBlog></NewsAndBlog>
            <Gallery></Gallery>
            </OrionLayout>
        );
    }
}
