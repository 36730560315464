import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import OrionHome from './home/home';
import AboutUs from './about-us/about-us';
import ProjectDetails from './project-details/project-details';
import Projects from './projects/projects';
import Careers from './careers/careers';
import Error from './error/error';
export default function OrionRoutes() {
  return (
    <Router>
      <Routes> 
        <Route exact path="/" element={<OrionHome />}></Route>
        <Route exact path="/about-us" element={<AboutUs />}></Route>
        <Route exact path="/projects" element={<Projects comingFrom={'projects'} />}></Route>
        <Route exact path="/projects/:projectID" element={<ProjectDetails />} />
        <Route exact path="/contact-us" element={<Projects />}></Route>
        <Route exact path="/careers" element={<Careers />}></Route>
        <Route path="*" element={<Error/>} />
      </Routes>
    </Router>
  );
}