const getProjectsDetails = function() {
    const projectDictionary = {
        'male-thilafushi-maldives' : {
            title: 'Male to Thilafushi Link Project-Maldives',
            img1: 'https://res.cloudinary.com/djxhh02io/image/upload/v1695811057/IMG_3296_ceiiqa.png',
            img2: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698582144/orion/IMG_3285_zue2ar.jpg',
            img3: 'https://res.cloudinary.com/djxhh02io/image/upload/v1695811435/IMG_3365-min_rzaxqm.png',
            listItems: [
                "Assignment Name: Greater Male’ Connectivity – Male to Thilafushi Link Project in the Republic of Maldives with the loan assistance of EXIM Bank.",
                "Description of Services: Supervision Consultant of the Detailed Design Consultant.",
                "Location: Greater Male, Maldives",
                "Name of Client: AFCONS Infrastructure Ltd./Government of Maldives",
                "Funding Authority: Exim Bank of India",
                "Project Cost: US$530 million",
                "Project Length: Approximately 6.70 kms comprising of approximately 3.70 kms of bridge section, and approximately 3.00 kms of at-grade roads",
                "Start Date (Month/Year): May 2022",
                "Completion Date (Month/Year): April 2024 (Ongoing Project)",
                "Project Specifications: The Greater Male' Connectivity – Male' to Thilafushi Link Project – the “Project”, also known as the Male’ to Thilafushi Link (MTL). The Project entails the construction of a new road and bridge link between Male and Thilafushi connecting 4 islands in the Republic of Maldives. The project is a symbol of the India-Maldives ties. The Project being built under grant and concessional loan support from India. This is the largest landmark infrastructure project in Maldives - that will enhance mobility between Male’, Villingili, Gulhifalhu and Thilafushi islands, cut down logistics cost, and drive people-centric economic growth - which will be a symbol of enduring friendship between the two countries."
            ],
        },
        'green-national-highways-corridor' : {
            title: 'Green National Highways Corridor Projects',
            img1: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698582856/orion/WhatsApp_Image_2023-05-09_at_12.11.20_PM_kh8bhu.jpg',
            img2: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698582854/orion/IMG_20230421_170324_rsd6nl.jpg',
            img3: 'https://res.cloudinary.com/djxhh02io/image/upload/v1695871119/IMG_20230421_161906_dqozo3.jpg',
            listItems: [
                "Assignment Name: Project Management Consultancy Services for Rehabilitation and Upgrading to 2-lanes/2-lane with paved shoulders/4 lane configuration from single/intermediate lane and strengthening of stretches of various National Highways in the states of Rajasthan, Himachal Pradesh, Uttar Pradesh and Andhra Pradesh and under Green National Highways Corridor Projects (GNHCP) including Balance Activities related to National Highway Interconnectivity Improvement Project in the states of  Bihar, Orissa and Karnataka with the loan assistance of World Bank.",
	            "Description of Services: Project Management Consultancy Services ",
	            "Location: Rajasthan, Himachal Pradesh, Uttar Pradesh, Andhra Pradesh, Bihar, Orissa and Karnataka/ India",
	            "Name of Client: Ministry of Road Transport and Highways - World Bank",
	            "Funding Authority: The World Bank",
	            "Project Cost: INR 49200 million",
	            "Project Length: 781.3 Km",
	            "Start Date (Month/ Year): September 2021",
	            "Completion Date (Month/ Year): August 2029 (Ongoing Project)",
	            "Project Specifications: This is a significant project to improve connectivity in the concerned states while ensuring to create safe and better environmental conditions on Roads. Considering the importance of the road network for economic growth, employment generation and poverty alleviation on one hand and the need to reduce adverse effects of vehicular pollution, forest diversion and felling of trees on the other, the MoRTH has launched a National Green Highways Mission (NGHM) following the promulgation of ‘Green Highways Policy’ and these roads will act as 'Model Roads' for future road infrastructure development in the country.",
            ],
        },
        'himachal-pradesh-state-roads-transformation' : {
            title: 'Himachal Pradesh State Roads Transformation Project',
            img1: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698583063/orion/WhatsApp_Image_2023-08-03_at_11.46.28_vejilw.jpg',
            img2: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698583063/orion/WhatsApp_Image_2023-08-03_at_11.46.27_1_igicnw.jpg',
            img3: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698583063/orion/Outlook-l53beoea_dftbue.jpg',
            listItems: [
                "Assignment Name: Consultancy Services For 'Project Management Consultant' For Implementation of World Bank Funded HPSRTP In Himachal Pradesh.",
	            "Description of Services: Project Management Consultancy Services ",
	            "Location: Himachal Pradesh, India",
	            "Name of Client: Himachal Pradesh Road & Infrastructure Development Corporation Ltd. (HPRIDC)",
	            "Funding Authority: The World Bank",
	            "Project Cost: US$110 Million",
	            "Project Length: Feasibility study of about 2000 Kms. and Detailed Engineering/Safeguard Assessment of about 650 Kms. of roads for improvement and up gradation of State core road network",
	            "Start Date (Month/ Year): April 2021",
	            "Completion Date (Month/ Year): March 2026 (Ongoing Project)",
	            "Project Specifications: The Government of Himachal Pradesh through the Govt. of India has applied for a loan to be financed from World Bank through Multi-tranche approach to meet the cost of Himachal Pradesh State Roads Transformation Project for improvement and up-gradation of State core road network and strengthening its Road Sector management capacity. The major scope of work includes 1. Strengthening HP’s transport institutions (PWD & Department of Transport (DOT)) and building resilience, 2. Developing transport corridors and complementary facilities, and a logistics system for HPs horticulture and overall economic growth, 3. Enhancing road safety through implementation of ‘the safe system’ and ‘the safe corridor initiative’ which will include support to improve road accident data management and enforcement on state roads on select high-traffic and high-risk corridors of the state.",
            ],
        },
        'meghalaya-integrated-transport' : {
            title: 'Meghalaya Integrated Transport Project',
            img1: 'https://res.cloudinary.com/djxhh02io/image/upload/v1695876144/1680347144238_ysvz18.jpg',
            img2: 'https://res.cloudinary.com/djxhh02io/image/upload/v1695876144/1680347144111_lqippx.jpg',
            img3: 'https://res.cloudinary.com/djxhh02io/image/upload/v1695876143/1680347144214_1_n94hcx.jpg',
            listItems: [
                    "Assignment Name: Consulting services for construction supervision & contract administration in the state of Meghalaya, under Meghalaya Integrated Transport Project.",
	                "Description of Services: Review of Detailed Engineering Designs, Construction Supervision & Contract Administration",
	                "Location: Meghalaya (India)",
	                "Name of Client: Public Works Department (Roads), Meghalaya, Shillong.",
	                "Funding Authority: The World Bank",
	                "Project Cost: US$120 million",
	                "Project Length: 300 Km",
	                "Start Date (Month/ Year): April 2021",
	                "Completion Date (Month/ Year): March 2025 (Ongoing Project)",
	                "Project Specifications: The State of Meghalaya has applied for a financial assistance of US$120 million to the World Bank for the Meghalaya Integrated Transport Project (MITP) to improve transport connectivity and efficiency, and to improve transport sector management in Meghalaya. Amidst many other activities, the project includes improvement and rehabilitation of about 300 km of State Highways (SH) and Major District Roads (MDR) including construction of missing bridges and replacement of semi-permanent timber bridges to improve regional and national connectivity and reduce urban congestion.",
            ],
        },
        'rebuild-kerala-initiative-for-central-districts-1' : {
            title: 'Rebuild Kerala Initiative for Central Districts of the State (Lot 1)',
            img1: 'https://res.cloudinary.com/djxhh02io/image/upload/v1696780079/IMG-20230802-WA0030_ajghq4.jpg',
            img2: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698583370/orion/jpeg-optimizer_IMG_0684_nkhfy7.jpg',
            img3: 'https://res.cloudinary.com/djxhh02io/image/upload/v1696780251/WhatsApp_Image_2023-08-02_at_3.16.03_PM_2_uezfhw.jpg',
            listItems: [
                   "Assignment Name: Consulting Services for Construction Supervision Climate-Resilient Reconstruction after Flooding in Kerala under Rebuild Kerala Initiative-Lot-1 ",
                   "Description of Services: Construction Supervision",
                   "Location: Kerala (India) ",
                   "Name of Client: Kerala State Transport Project (KSTP),",
                   "Funding Authority: Funded by KFW",
                   "Project Cost: INR 8724.6 Million",
                   "Project Length: 206.23 Km",
                   "Start Date (Month/ Year): April 2021",
                   "Completion Date (Month/ Year): Oct. 2024 (Ongoing Project)",
                   "Project Specifications: The heavy monsoons of 2018 and 2019 brought widespread flooding to several districts of Kerala damaging about 2,000 km of state highways and 13,000 km of major district roads across 14 districts. PWD is considering developing a more sustainable and climate resilient asset stock in the medium to long term. For that purpose, approximately 700 km of roads have been selected. The “building back better” principles is be applied for the rehabilitation of these roads. ",
            ],
        },
        'rebuild-kerala-initiative-for-central-districts-2' : {
            title: 'Rebuild Kerala Initiative for Central Districts of the State (Lot 2)',
            img1: 'https://res.cloudinary.com/djxhh02io/image/upload/v1696781246/IMG-0716_wwnvya.jpg',
            img2: 'https://res.cloudinary.com/djxhh02io/image/upload/v1696781214/IMG-0714_vll4cl.jpg',
            img3: 'https://res.cloudinary.com/djxhh02io/image/upload/v1696781020/59667143-b906-4750-97e9-71b8f98e2244_mj8sjk.jpg',
            listItems: [
                "Assignment Name: Consulting Services for Construction Supervision Climate-Resilient Reconstruction after Flooding in Kerala under Rebuild Kerala Initiative-Lot-2",
                "Description of Services: Construction Supervision",
                "Location: Kerala (India)",
                "Name of Client: Kerala State Transport Project (KSTP),",
                "Funding Authority: Funded by KFW & The World Bank",
                "Project Cost: INR 6130 Million",
                "Project Length: 249.26 Km",
                "Start Date (Month/ Year): April 2021",
                "Completion Date (Month/ Year): Oct. 2024 (Ongoing Project)",
                "Project Specifications: The heavy monsoons of 2018 and 2019 brought widespread flooding to several districts of Kerala damaging about 2,000 km of state highways and 13,000 km of major district roads across 14 districts. PWD is considering developing a more sustainable and climate resilient asset stock in the medium to long term. For that purpose, approximately 700 km of roads have been selected. The “building back better” principles is be applied for the rehabilitation of these roads. ",
            ],
        },
        'rebuild-kerala-initiative-for-northern-districts' : {
            title: 'Rebuild Kerala Initiative for Northern Districts of the State (Authority’s Engineer)',
            img1: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698584634/orion/image_4_yfvcwi.png',
            img2: 'https://res.cloudinary.com/djxhh02io/image/upload/v1696778474/WhatsApp_Image_2023-08-02_at_3.16.03_PM_2_i1hjtu.jpg',
            img3: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698584577/orion/image_3_irwdmr.png',
            listItems: [
                    "Assignment Name: Authority’s Engineer for Supervision of ‘Rehabilitation & Upgrading the State Highways & Major District Roads, having varying width, by Climatic Resilient Reconstruction, 4 roads in the Northern Districts of Mallapuram & Palakkad of Kerala State (Package -2) under Rebuild Kerala Initiative’.",
	                "Description of Services: Authority’s Engineer",
	                "Location: Kerala (India)",
	                "Name of Client: Kerala State Transport Project (KSTP)",
	                "Funding Authority: The World Bank",
	                "Project Cost: INR 7897 Million",
	                "Project Length: 128.29 Km",
	                "Start Date (Month/ Year): November 2020",
	                "Completion Date (Month/ Year): Oct. 2027 (Ongoing Project)",
	                "Project Specifications: Government of Kerala, through Government of India, has obtained loan from the International Bank for Reconstruction & Development (World Bank) as financial assistance for the construction cost of the Rebuild Kerala Initiative (RKI) program, which aims at Rehabilitation & Upgrading the identified State Highways & Major District Roads affected after natural disaster due to floods & damages by excessive rains in year 2018 & 2019. These roads, having varying width, are proposed to be built better by Climate Resilient Reconstruction of roads infrastructure, in the Northern Districts of Kerala State having plain terrain and hilly/rolling terrain under Rebuild Kerala Initiative’.",
            ],
        },
        'rajasthan-secondary-towns-development-sector' : {
            title: 'Rajasthan Secondary Towns Development Sector Project (RSTDSP)',
            img1: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698584868/orion/WhatsApp_Image_2023-10-29_at_6.36.43_PM_1_phsinz.jpg',
            img2: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698584868/orion/WhatsApp_Image_2023-10-29_at_6.36.43_PM_d2wyjf.jpg',
            img3: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698584868/orion/WhatsApp_Image_2023-10-29_at_6.36.42_PM_1_uqmh30.jpg',
            listItems: [
                "Assignment Name: Rajasthan Secondary Towns Development Sector Project (RSTDSP): Consultancy Services for Community Awareness and Public Participation (CAPP) Consultant",
                "Description of Services: Community Awareness and Public Participation (CAPP) Consultant",
                "Location: Rajasthan (India)",
                "Name of Client: Rajasthan Urban Infrastructure Development Project (RUIDP)",
                "Funding Authority: Asian Development Bank (ADB)",
                "Project Cost: INR 389 Million",
                "Start Date (Month/ Year): Sept. 2021",
                "Completion Date (Month/ Year): August 2028 (Ongoing Project)",
                "Project Specifications: The project is to support the GoR towards improving the water and waste water services in 42 secondary towns and aiming to sustainable urban development in secondary towns. Project includes rehabilitation and expansion of water supply network, sewerage network, modernization and new construction of water and WTPs, fecal sludge management, and decentralized wastewater management systems in the project towns. The project is expected to increase operational efficiency, improve service delivery, and result in positive impact on health and quality of life for the residents of the project towns in the state.",
            ],
        },
        'development-of-ramseen-bhinmal-raniwara-section' : {
            title: 'IE Services for Development and Maintenance of Ramseen-Bhinmal-Raniwara Section of SH-31',
            img1: 'https://res.cloudinary.com/djxhh02io/image/upload/v1696777725/IMG-20230807-WA0022_vukuku.jpg',
            img2: 'https://res.cloudinary.com/djxhh02io/image/upload/v1696777721/IMG-20230807-WA0024_hp4bnp.jpg',
            img3: 'https://res.cloudinary.com/djxhh02io/image/upload/v1696777714/1691467452168_e0gt66.jpg',
            listItems: [
                "Assignment Name: Independent Engineer Services for Development and Maintenance of Two Lane of Ramseen-Bhinmal-Raniwara Section of SH-31 from Design (Km 0.000 to 62.619) existing Km 43.800 to 107.600 (Total Length 62.619 Km) in the state of Rajasthan under on Hybrid Annuity Mode (WB/RSHDP-II/Annuity/04)",
	            "Description of Services: Independent Engineer Services",
	            "Location: Rajasthan (India)",
	            "Name of Client: Public Works Department, Government of Rajasthan",
	            "Funding Authority: The World Bank",
	            "Project Cost: INR 2477 Million",
	            "Project Length: 62.619 Km",
	            "Start Date (Month/ Year): August 2022",
	            "Completion Date (Month/ Year): May 2025 (Ongoing Project)",
	            "Project Specifications: The Government of Rajasthan through Government of India has received financing from the World Bank toward the cost of Rajasthan State Highways Development Program II. The state government launched RSHDP to develop and upgrade State Highways (SH) using the latest technology to cater to the ever-increasing traffic and overloading over the years. The project aims for Development and Maintenance of Two Lane of Ramseen-Bhinmal-Raniwara Section of SH-31 from Design (Km 0.000 to 62.619) existing Km 43.800 to Km 107.600 (Total Length 62.619 Km) on Hybrid Annuity Mode.",
            ],
        },
        'assam-integrated-river-basin-management-project' : {
            title: 'Assam Integrated River Basin Management Project',
            img1: 'https://res.cloudinary.com/djxhh02io/image/upload/v1696778218/WhatsApp_Image_2023-08-04_at_4.41.03_PM-2_jicy05.jpg',
            img2: 'https://res.cloudinary.com/djxhh02io/image/upload/v1696778215/WhatsApp_Image_2023-08-04_at_4.41.02_PM_gx90xb.jpg',
            img3: 'https://res.cloudinary.com/djxhh02io/image/upload/v1696778219/WhatsApp_Image_2023-08-04_at_4.41.03_PM_d7nbqh.jpg',
            listItems: [
                    "Assignment Name: Consulting Services for: Project Management and Technical Consultants for Assam Integrated River Basin Management Project.",
	                "Description of Services: Project Management and Technical Consultants ",
	                "Location: Assam (India)",
	                "Name of Client: Flood & River Erosion Management Agency of Assam",
	                "Funding Authority: The World Bank",
	                "Project Cost: USD 120 Million",
	                "Start Date (Month/ Year): Sept. 2022",
	                "Completion Date (Month/ Year): August 2026 (Ongoing Project)",
	                "Project Specifications: The Objective of the project is to strengthen institutional capacity for integrated water resources planning and management, and to enhance preparedness for flood and river erosion risks in Assam. The project aims at (i) reducing flood and river erosion risks in the Assam parts of the Beki and Buridehing sub-basins, (ii) enhance the resilience of local communities to flood and river erosion in selected locations. (iii) prepare integrated water resource management plans in the Beki, Buridehing, and Jiadhal sub-basins to support future investments in subsequent program phases. ",
            ],
        },
        'meghalaya-ecotourism-infrastructure-development' : {
            title: 'Meghalaya Ecotourism Infrastructure Development Project (MEIDP)',
            img1: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698577453/orion/IMG-20230803-WA0001_w9ctke.jpg',
            img2: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698577449/orion/IMG-20230825-WA0006_zignxc.jpg',
            img3: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698577465/orion/IMG20230526112715_n7i9qy.jpg',
            listItems: [
                "Assignment Name: Consulting Services for Construction Supervision and Contract Administration in the State of Meghalaya under Meghalaya Ecotourism Infrastructure Development Project.",
                "Description of Services: Construction Supervision and Contract Administration",
                "Location: Meghalaya (India)",
                "Name of Client: Shah Technical Consultant Pvt Ltd / Meghalayan Age Limited",
                "Funding Authority: New Development Bank",
                "Project Cost: USD 80 Million",
                "Key Component: The project has been broadly divided into the following components and the subprojects would be implemented across the State.",
                "a. Iconic Projects: 9 sub-projects",
                "b. Accommodation Units: 19 sub-projects",
                "c. Roads connecting key tourist destinations/ circuits: 03 sub-projects",
                "Start Date (Month/ Year): Dec. 2022",
                "Completion Date (Month/ Year): Nov. 2026 (Ongoing Project)",
                "Project Specifications: With assistance from the New Development Bank, the Government of Meghalaya has envisaged the ‘Meghalaya Ecotourism Infrastructure Development Project (MEIDP)’ as a systematic approach to achieve inclusive growth by focusing on eco-tourism while ensuring good governance, convergence, institutional strengthening, capacity building, knowledge management, communications, financial inclusion and access to markets. This project will provide economic incentives for the people of Meghalaya to make capital investment in natural resources and to work for its conservation in order to ensure sustainability of their own dependent livelihoods and growth opportunities.",
            ],
        },
        'kerala-thiruvananthapuram-kollam-pathanamthitta-a' : {
            title: 'Kerala Solid Waste Management Project for Thiruvananthapuram, Kollam and Pathanamthitta Districts (Package A)',
            img1: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698584020/orion/image_a3jizb.png',
            img2: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698584020/orion/image_1_xbggdt.png',
            img3: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698584417/orion/WhatsApp_Image_2023-03-31_at_14.26.38_3_srrb1q.jpg',
            listItems: [
                    "Assignment Name: Technical Support Consultants for package A (Thiruvananthapuram, Kollam and Pathanamthitta Districts) of the Kerala Solid Waste Management Project.",
	                "Description of Services: Technical Support Consultants",
	                "Location: Kerala (India)",
	                "Name of Client: Kerala Solid Waste Management Project (KSWMP) (under Local Self Government Department, Government of Kerala)",
	                "Funding Authority: The World Bank",
	                "Project Cost: USD 300 Million",
	                "Start Date (Month/ Year): Dec. 2022",
	                "Completion Date (Month/ Year): Nov. 2027 (Ongoing Project)",
	                "Project Specifications: Kerala Solid Waste Management Project (KSWMP), a pilot project on solid waste, being implemented first time in India, with the assistance of the World Bank (WB) to make the cities of Kerala free from Solid Waste. The Objective of the Project is to strengthen the institutional and service delivery systems for SWM in Kerala. In particular the project aims to adopt sector wide integrated value chain approach for enhancing the service delivery for waste management in Kerala.",
            ],
        },
        'kerala-thiruvananthapuram-kollam-pathanamthitta-c' : {
            title: 'Kerala Solid Waste Management Project for Thrissur, Palakkad & Malappuram Districts (Package C)',
            img1: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698584291/orion/WhatsApp_Image_2023-03-31_at_14.26.38_11_zsuhul.jpg',
            img2: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698584329/orion/WhatsApp_Image_2023-05-02_at_13.20.45_iyasyg.jpg',
            img3: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698584370/orion/WhatsApp_Image_2023-05-02_at_13.24.06_rfag0c.jpg',
            listItems: [
                "Assignment Name: Technical Support Consultants for package C (Thrissur, Palakkad & Malappuram Districts) of the Kerala Solid Waste Management Project.",
                "Description of Services: Technical Support Consultants",
                "Location: Kerala (India)",
                "Name of Client: Kerala Solid Waste Management Project (KSWMP) (under Local Self Government Department, Government of Kerala)",
                "Funding Authority: The World Bank",
                "Project Cost: USD 300 Million",
                "Start Date (Month/ Year): Dec. 2022",
                "Completion Date (Month/ Year): Nov. 2027 (Ongoing Project)",
                "Project Specifications: Kerala Solid Waste Management Project (KSWMP), a pilot project on solid waste, being implemented first time in India, with the assistance of the World Bank (WB) to make the cities of Kerala free from Solid Waste. The Objective of the Project is to strengthen the institutional and service delivery systems for SWM in Kerala. In particular the project aims to adopt sector wide integrated value chain approach for enhancing the service delivery for waste management in Kerala.  ",
            ],
        },
        'rajasthan-rural-water-supply-and-fluorosis-mitigation' : {
            title: 'Rajasthan Rural Water Supply and Fluorosis Mitigation Project (Phase-II)',
            img1: 'https://res.cloudinary.com/djxhh02io/image/upload/v1696782241/gallery-1-34_wl1ptw.png',
            img2: 'https://res.cloudinary.com/djxhh02io/image/upload/v1696782241/Project-10-160-MLD-WTP-e1436970609940_wcxrc6.jpg',
            img3: 'https://res.cloudinary.com/djxhh02io/image/upload/v1696782424/bisalpur-jaipur-water-supply-scheme-at-jaipur-rajasthan-1_e2wztp.jpg',
            listItems: [
                "Assignment Name: Project Management and Supervision Consultancy (PMSC) for the Rajasthan Rural Water Supply and Fluorosis Mitigation Project (Phase-II)",
                "Description of Services: Project Management and Supervision Consultancy",
                "Location: Rajasthan (India)",
                "Name of Client: Public Health Engineering Department, Jodhpur, Rajasthan",
                "Funding Authority: JICA ",
                "Project Cost: USD 308 Million",
                "Start Date (Month/ Year): June 2023",
                "Completion Date (Month/ Year): May 2027 (Ongoing Project)",
                "Project Specifications: The Government of Rajasthan State (GOR) is promoting the water supply development projects in all districts in order to provide residents with piped water supply facilities. Public Health Engineering Department (PHED) is responsible for planning, building, operating, and maintaining urban and rural drinking water supply in the state.",
            "The Project consists of five packages in two districts, as shown below:",
            "Construction in Jhunjhunu",
            "・ CP-1: Surajgarh and Udaipurwati sub-scheme",
            "Construction in Barmer",
            "・ CP-2: HR-1&HR-2 sub-scheme",
            "・ CP-3: Chohtan-1 sub-scheme",
            "・ CP-4: Chohtan-2 sub-scheme",
            "Soft Component",
            "・ CP-5: IEC activities including FMP",
            ],
        },
        'Project-Supervision-Services(PSS)-for-Gati-Shakti-Unit-Ajmer-of-North-Western-Railway' : {
            title: 'Project Supervision Services Agency (PSSA) for Providing Project Supervision Services at Various Construction Sites/Sections in the jurisdiction of Chief Project Manager /Gati Shakti Unit /Ajmer of North Western Railway.',
            img1: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1708090555/orion-website/projects/Ajmer%20railway/WhatsApp_Image_2023-08-04_at_4.41.03_PM_s89hos.jpg',
            img2: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1708090544/orion-website/projects/Ajmer%20railway/WhatsApp_Image_2024-02-16_at_10.34.19_AM-2_1_xiuk4s.jpg',
            img3: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1708090531/orion-website/projects/Ajmer%20railway/WhatsApp_Image_2023-08-04_at_4.41.03_PM-2_nb9lny.jpg',
            listItems: [
                "Assignment Name: Project Supervision Services Agency (PSSA) for Providing Project Supervision Services at Various Construction Sites/Sections in the jurisdiction of Chief Project Manager /Gati Shakti Unit /Ajmer of North Western Railway.",
                "Description of Services: Project Supervision Services Agency (PSSA)",
                "Location: Rajasthan (India)",
                "Name of Client: Chief Project Manager, Gati Shakti Unit, Mall Road, Ajmer, N.W. Railway, Ministry of Railways Government of India",
                "Project Cost: INR 12.35 Cr",
                "Start Date (Month/ Year): January 2024",
                "Completion Date (Month/ Year): December 2025 (Ongoing Project)",
                "Project Specifications: The objectives of the Supervision services are to assist the Employer to implement the Project in conformity with Railways’ rules and regulations; and codes Local laws, bye laws, regulations, rules etc.",
            "The PSSA will ensure:",
            "(i) That the Project is implemented up to the last detail for commencement of commercial operations by the Railway administration and provide assistance to the Employer up to the end of `Defect Liability Period’ under the Contract.",
            "(ii) That the Contractor’s are observing due diligence and prevailing standards in the performance of the assignment.",
            "(iii) Compliance of all rules of railways related to the execution of the project. Special care will be taken in imposing necessary speed restriction, caution, arranging necessary traffic blocks &amp; OHE blocks, where necessary etc. in order to ensure safety at all times.",
            "(iv) Accuracy, quality of workmanship safety of the works being carried out by the contractors, etc.",
            ]
        },
        'Project-Implementation-Support-Consultant-for-CRBIFRERMP-Project' : {
            title: 'Climate Resilient Brahmaputra Integrated Flood and Riverbank Erosion Risk Management Project in Assam',
            img1: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1708090684/orion-website/projects/FREMAA%20ADB/1707500057403_ayfixu.jpg',
            img2: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1708090683/orion-website/projects/FREMAA%20ADB/1707500061117_f9zyfe.jpg',
            img3: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1708090682/orion-website/projects/FREMAA%20ADB/1707500059542_y0thlz.jpg',
            listItems: [
                "Assignment Name:Project Implementation Support Consultant for Climate Resilient Brahmaputra Integrated Flood and Riverbank Erosion Risk Management Project in Assam",
                "Description of Services: Project Implementation Support Consultant",
                "Location: Assam (India)",
                "Name of Client: Flood and River Erosion Management Agency of Assam (FREMAA)",
                "Funding Authority: Asian Development Bank (ADB)",
                "Project Cost: USD 250 million",
                "Start Date (Month/ Year): February 2024",
                "Completion Date (Month/ Year): August 2029 (Ongoing Project)",
                "Project Specifications: The main objectives of the project are to",
                "(i) reduce in a climate resilient way economic vulnerability and social disruption induced by flood and riverbank erosion in selected flood and erosion prone areas along the main stem of the Brahmaputra River in Assam",
                "(ii) strengthen the institutional capacity for climate and disaster resilient FRERM",
                "(iii) strengthen the knowledge-base for informed decision-making on preventive measures, including forecasting and warnings, asset management, and preparation of river stabilization guidelines and plans",
                "(iv) stabilize selected river reaches integrating erosion and flood protection works to ultimately enhance navigation, and recover lost floodplain land (‘land reclamation’) and",
                "(v) enhance the livelihoods of the riverine population."
            ]
        },
        'PMS-for-Development-of-New-Ambaji-Railway-Station-and-New-Tarangahill-Railway-Station-and-Construction-Of-4-Lane-ROBs' : {
            title: 'PMS for Development of New Ambaji Railway Station and New Tarangahill Railway Station and Construction Of 4 Lane ROBs',
            img1: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1720156610/orion-website/projects/ambaji%20railway%20station%20project/3b9f3967-304e-4bd5-827f-811d92bad6b1_vlj1sh.jpg',
            img2: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1720156594/orion-website/projects/ambaji%20railway%20station%20project/3a4dcca5-a2e7-4879-af71-7a0915add6e5_rjv0ym.jpg',
            img3: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1720156600/orion-website/projects/ambaji%20railway%20station%20project/179108e8-4127-41ee-a60f-35df1fe33bff_lohaoc.jpg',
            listItems: [
                "Assignment Name: Authority Engineer for providing Project Management Services for Development of New Ambaji Railway Station and Development of New Tarangahill Railway Station and construction of 4 lane Road Over Bridge no. 1, railway chainage 1133 and Railway Bridge (Major Bridge no. 116 at railway chainage 42760) for Taranga Hill – Ambaji – Abu road New Railway Line Project.",
                "Description of Services: Project Management Services",
                "Location: Rajasthan - Gujarat (India)",
                "Name of Client: Dy. Chief Engineer (Const.), Ajmer, Near GLO Ground, Mall Road, North Western Railway.",
                "Project Cost: INR 272 Cr.",
                "Start Date (Month/ Year): May 2024",
                "Completion Date (Month/ Year): May 2027 (Ongoing Project)",
                "Project Specifications:Project includes supervision, monitoring, reporting, scheduling and other works. PMS are to assist the Authority to implement the Project in conformity with the EPC Agreement, Railways’ rules and regulations, and codes Local laws, bye laws, regulations, rules etc. Project includes development of New Ambaji Railway Station Building and development of New Taranga Hill Railway Station Building and 2 Nos. of 4 lane ROBs.",
            ],
        }
        }
        return projectDictionary;
}
exports.getProjectsDetails = getProjectsDetails;