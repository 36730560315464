
import React from 'react'; 
export default class Error extends React.Component {
    constructor(props){
        super(props);
        console.log('This is Error Component');
    }
    render(){
        return (<h1>This is Error Component</h1>);
    }
}
