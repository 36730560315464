import React, { useState, useEffect } from 'react';
import './CarouselComponent.scss';

const CarouselComponent = () => {
  const images = [
    'https://res.cloudinary.com/dyihxlupl/image/upload/v1693126547/orion-website/home-page/banner/pexels-annam-w-1057858_j2mson_omscsl.jpg',
    'https://res.cloudinary.com/dyihxlupl/image/upload/v1693126539/orion-website/home-page/banner/tony-pepe-55hkmIInfqU-unsplash_gu7289_jvtnin.jpg',
    'https://res.cloudinary.com/dyihxlupl/image/upload/v1693126530/orion-website/home-page/banner/pexels-pixabay-220762_tjv4tm_1_g5ya6r.jpg',
  ];

  const [currentIndex, setCurrentIndex] = useState(1);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      handleNext();
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, [currentIndex]);

  return (
    <div className='orion-carousel'>
      <div className="carousel">
        <div className="hero-section">
          {/* <div className="company-name">ORION</div> */}
          {/* <p className='company-title'>Re-imagining Infrastructure</p> */}
        </div>
      <button className="prev-btn" onClick={handlePrev}>&#9664;</button>
      <img className="banner-image" src={images[currentIndex]} alt="Banner" />
      <button className="next-btn" onClick={handleNext}>&#9654;</button>
      <div className="indicators">
    {images.map((_, index) => (
      <span
        key={index}
        className={`indicator ${index === currentIndex ? 'active' : ''}`}
        onClick={() => setCurrentIndex(index)}
      ></span>
    ))}
  </div>
    </div>
    
  </div>
  );
};

export default CarouselComponent;
