import React, { Component } from 'react';
import './sectors-main.scss';

class SectorsMain extends Component {
  render() {
    const sectors = [
      { icon: 'https://res.cloudinary.com/djxhh02io/image/upload/v1693064851/Screenshot_2023-08-26_at_9.05.50_PM_h7beva.png', name: 'Highways & Expressways' },
      { icon: 'https://res.cloudinary.com/djxhh02io/image/upload/v1693064850/Screenshot_2023-08-26_at_9.10.31_PM_qzhcky.png', name: 'Bridges and Other Structures' },
      { icon: 'https://res.cloudinary.com/djxhh02io/image/upload/v1693064849/Screenshot_2023-08-26_at_9.11.23_PM_aenzie.png', name: 'Metro, Railways, High Speed Rail' },
      { icon: 'https://res.cloudinary.com/djxhh02io/image/upload/v1693064850/Screenshot_2023-08-26_at_9.12.09_PM_eenznm.png', name: 'Urban Infrastructure & Smart Cities' },
      { icon: 'https://res.cloudinary.com/djxhh02io/image/upload/v1693064850/Screenshot_2023-08-26_at_9.12.29_PM_lclhpm.png', name: 'Water Resources & Irrigation' },
      { icon: 'https://res.cloudinary.com/djxhh02io/image/upload/v1693064850/Screenshot_2023-08-26_at_9.12.56_PM_kbqjwj.png', name: 'Water Supply & Waste Water' },
      { icon: 'https://res.cloudinary.com/djxhh02io/image/upload/v1693064850/Screenshot_2023-08-26_at_9.13.54_PM_kw28nj.png', name: 'Airports' },
      { icon: 'https://res.cloudinary.com/djxhh02io/image/upload/v1693064851/Screenshot_2023-08-26_at_9.14.36_PM_ypzbx6.png', name: 'Ports' },
      { icon: 'https://res.cloudinary.com/djxhh02io/image/upload/v1693064851/Screenshot_2023-08-26_at_9.14.55_PM_zxhgth.png', name: 'Power' },
      { icon: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1695799046/orion-website/home-page/sectors/agriculture_ijbezw.png', name: 'Agriculture' },
      { icon: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1695799044/orion-website/home-page/sectors/disaster_umcsna.png', name: 'Disaster Management & Climate Resilience' },
      { icon: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1695799040/orion-website/home-page/sectors/tourism_obokb7.png', name: 'Tourism' },
      { icon: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1695799028/orion-website/home-page/sectors/waste_mp2ljx.png', name: 'Solid Waste Management' },
      { icon: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1695799028/orion-website/home-page/sectors/waste_mp2ljx.png', name: 'Sewerage' },
      
    ];

    return (
      <div className='sectors-orion' id="sectors">
        <div className="row m-0">
        <h2 className="w-100 text-center mt-5 mb-0">Our Sectors</h2>
    </div>
      <div className="sectors pb-5">
        {sectors.map((sector, index) => (
          <div key={index} className="sector">
            <img src={sector.icon} alt={`Icon ${index}`} className="icon" />
            <div className="sector-name">{sector.name}</div>
          </div>
        ))}
      </div>
      </div>
    );
  }
}

export default SectorsMain;

