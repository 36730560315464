import React from 'react';
import './footer.scss';


const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-left d-md-flex">
          <div className="footer-logo">
            {/* Insert your company logo here */}
            <img src="https://res.cloudinary.com/djxhh02io/image/upload/v1685101133/Logo_ixgc53.png" alt="Company Logo" />
          </div>
          <div className="footer-address ps-lg-5">
            <p><b>5/365, SFS, Agrawal Farm, Mansarovar, </b></p>
            <p><b>Jaipur, Rajasthan - 302020</b></p>
          </div>
          <div className="footer-social-icons ps-lg-5">
            {/* LinkedIn icon */}
            <a href="https://www.linkedin.com/company/orion-infra-consulting-pvt-ltd/" target="_blank" rel="noopener noreferrer">
              <img src="https://res.cloudinary.com/djxhh02io/image/upload/v1693128146/Screenshot_2023-08-27_at_2.51.34_PM_idkloh.png" alt="LinkedIn" />
            </a>
            {/* Facebook icon */}
            <a href="https://m.facebook.com/people/Orion-Infra-Consulting/100063849608484/" target="_blank" rel="noopener noreferrer">
              <img src="https://res.cloudinary.com/djxhh02io/image/upload/v1693128145/Screenshot_2023-08-27_at_2.49.45_PM_yjyi75.png" alt="Facebook" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

