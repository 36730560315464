import React, { Component } from 'react';
import '../about-us/about-us.scss';

class HomeAboutUs extends Component {
    render() {
        return (
            <section id="about-home-us" className="about-us-component py-5">
                <div className="container">
                    <div className="row m-0">
                        <div className="col-12">
                            <h2 className="w-100 text-center mt-5">Company Overview</h2>
                        </div>
                        <div className="col-1 col-xs-0 col-sm-0 col-lg-1 col-md-1"></div>
                        <div className="col-10 col-xs-12 col-sm-12 col-lg-10 col-md-10">
                            <p><strong>Orion Infra Consulting Pvt Ltd</strong> started it’s business in June 2020 to undertake specialized construction monitoring consultancy services in the field of Infrastructure. Orion assists organizations across the infrastructure development value chain to transform ideas into reality.</p>
                            <p>As a strategic partner, we help develop and implement results-driven solutions to the most complex challenges.</p>
                            <p>We now operate in almost all the major infrastructure sectors which form the cornerstones of the country’s economic growth. Our areas of expertise include Highways, Expressways, Bridges, Railways & Metros, Smart Cities, Ports, Power, Tunnels, Water & Waste Water, Irrigation, Water Resources, Solid Waste Management, Airport, Tourism, Urban Infrastructure Development, Sewerage and others. </p>
                            <p>Orion has been providing services from concept to commissioning like project concept Development, Project structuring, Project Management Consultancy, Construction Supervision Consultancy, Authority’s Engineer, General Consultant, Independent Engineers, Contract Administration, Contract Management, Operation & Maintenance Services, Community Awareness and Public Participation, Environmental & Social Management Plan and Climate Resilient and disaster Management, which has helped Orion to establish itself as one of the fastest growing Infra consultancy company in the Country.</p>
                            <p>Orion has successfully mobilized over 7 projects within the first year of its start of operation in various states of India and at present Orion has 16 projects in its portfolio in India and one international project in Maldives. Out of these 15 projects are funded by various multilateral funding agencies including The World Bank, Asian Development Bank (ADB), KFW (German Bank), NDB, JICA and EXIM Bank of India. We are currently working in the states of Gujarat, Bihar, Odisha, Karnataka, Kerala, Andhra Pradesh, Rajasthan, New Delhi, Himachal Pradesh, Uttar Pradesh, Meghalaya and Assam. </p>
                            <p>With over 200 highly qualified and experienced staff, our expertise spans all the major civil engineering disciplines and infrastructure sectors. With a single-minded focus on exceeding client’s expectations, our work is characterized by unwavering commitment, utmost reliability, exceptional quality, and on-time delivery. </p>
                            {/* <p>Orion has demonstrated robust financial health and consistent growth over the past fiscal years, underscoring its commitment to excellence. In the fiscal year 2024, Orion achieved a significant milestone with an annual turnover exceeding INR 168 million, which was previous year i.e. INR 135 million and INR 750 million in first year of it’s inception. This impressive growth is attributed to our success rates in the project.</p> */}
                        </div>
                        <div className="col-1 col-xs-0 col-sm-0 col-lg-1 col-md-1"></div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HomeAboutUs;