import React from 'react'; 
import './orion-layout.scss';
import OrionHeader from './orion-header/orion-header';
import OrionFooter from './orion-footer/orion-footer';

export default function OrionLayout(props) {
    return (
        <>
            <OrionHeader />
                <div className="layout-content-container">
                    {props.children}
                </div>
            <OrionFooter />
        </>
    );
}
