import React from "react";
import './careers.scss';
import moment from 'moment-timezone';
class Contact extends React.Component {
  componentDidMount() {
    var date = new Date();
    var nzlandTime = moment.tz(date, "Pacific/Auckland").format("HH mm A");
    this.setState({
      nzlandCurrentTime: nzlandTime
    });
  }
  render() {
    return (
      <section className="contact-section">
        <div className="container" id="get-in-touch">
          <div className="row">
            <div className="col-sm-12">
              <div className="contact-mf">
                <div id="contact" className="box-shadow-full">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="title-box-2 pt-4 pt-md-0">
                        <h5 className="title-left">Location</h5>
                      </div>
                      <div>
                        <iframe 
                          className="col-12 p-0"
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1805.7783785688268!2d75.77636914053525!3d26.846985238926315!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5affb7e1be9%3A0xf5eb8fb8a0f9959e!2sOrion%20Infra%20Consulting%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1690810647478!5m2!1sen!2sin"
                          width="100%"
                          height="380"
                          style={{boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2)', border:0}}
                          allowFullScreen="" 
                          loading="lazy">
                        </iframe>
                        </div>
                    </div>
                    <div className="col-md-6">
                      <div className="title-box-2 pt-4 pt-md-0">
                        <h5 className="title-left">Get in Touch</h5>
                      </div>
                      <div className="more-info">
                        <p className="lead">You Made It So Far</p>
                        <p className="lead">Thanks For Dropping By!</p>
                        <p className="lead">For Business Enquiries :</p>
                        <p className="lead"> Reach Us At : <a className="color-a" href="mailto:business@orioninfraconsulting.com">business@orioninfraconsulting.com</a>.</p>
                        <p className="lead">To Join Our Team:</p>
                        <p className="lead"> Reach Us At : <a className="color-a" href="mailto:bd@orioninfraconsulting.com">hr@orioninfraconsulting.com</a>.</p>
                      </div>
                      <div>
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="copyright-box">
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
    );
  }
}

export default Contact;