import React, { Component } from 'react';
import './about-us.scss';
import OrionLayout from '../../core/layouts/orion-layout/orion-layout';

class AboutUs extends Component {
  render() {
    return (
      <OrionLayout>
        <section id="about-us" className="about-us-component py-5">
        <div className="container">
          <h2>About Us</h2>
             <p>Orion Infra Consulting assists organisations across the infrastructure development value chain to transform ideas into reality. As your strategic partner, we help develop and implement result-driven solutions to the most complex challenges.</p>
             <p>We provide hands-on programme and project managers, support functions and trusted advisors to help clients optimise resource effectiveness. </p>
          <div className="chairman">
            <img src="https://res.cloudinary.com/djxhh02io/image/upload/v1685100698/Image_of_Mr_Sanjay_Jindal_nxhgdt.jpg" />
            <div className="chairman-message">
              <h3>Founder & CEO’s Message</h3>
              <p>It gives me great pleasure to mention that Orion has made major strides on all counts. The company with its new found Zeal has surpassed all expectations in its various performance parameters like order book, Turn over and Profitability. The order book as of now has surged to Rs 85 Cr (app) in the span of just 3 years, which is the testimony to the credibility, the company enjoys today with various clients. There is no denying the fact that Orion is one of the fastest growing and trusted organization in the infrastructure sector engaged in prestigious assignments in various sectors.</p>
               <p>Orion has been consistently taking initiatives to improve the functional efficiency and remain in sync with the increase in growth of business. Making collaboration with renowned international firms as knowledge partners are some of the recent strategic moves initiated by the company to bring more efficiency in the operation and to ensure better corporate governance.</p>
               <p>As Founder & CEO of Orion, want to assure you that we will do everything in our endeavor to conduct ourselves in a professional manner and be highly competitive and diligent in providing outstanding service to all our valuable clients in a timely, cost-effective and quality manner. We stand committed to our contractual commitments with our clients and we shall work diligently to earn your trust and respect and deliver business and results with excellence.</p>
               <p>We hope to be an internationally renowned Infra engineering company with multi-disciplinary domain expertise.</p>
               <p>Sanjay Jindal</p>
               <p>Founder & CEO</p>
            </div>
          </div>
        </div>
      </section>
      </OrionLayout>
    );
  }
}

export default AboutUs;