// src/WhyOrion.js
import React from 'react';
import './why-orion.scss';

function WhyOrion() {
  return (
    <section className="why-orion" id="why-choose-orion">
      <div className="container">
        <h2 className="why-orion-title">Why Orion?</h2>
        <div className="why-orion-content">
          <div className="reason">
            <div className="reason-icon">
              <i className="fas fa-users" style={{ color: '#d30000' }}></i>
            </div>
            <h3>Youthful & Agile Team</h3>
            <p>Our team combines youth, agility, and strong business acumen.</p>
          </div>
          <div className="reason">
            <div className="reason-icon">
              <i className="fas fa-handshake" style={{ color: '#d30000' }}></i>
            </div>
            <h3>Collaboration & Transparency</h3>
            <p>We emphasize collaboration, transparency, and handling project complexities.</p>
          </div>
          <div className="reason">
            <div className="reason-icon">
              <i className="fas fa-briefcase" style={{ color: '#d30000' }}></i>
            </div>
            <h3>Trusted Advisors</h3>
            <p>We are trusted advisors for comprehensive services from concept to commissioning.</p>
          </div>
          <div className="reason">
            <div className="reason-icon">
              <i className="fas fa-dollar-sign" style={{ color: '#d30000' }}></i>
            </div>
            <h3>Cost-Effective Solutions</h3>
            <p>We deliver cost-effective solutions and support with lean operations.</p>
          </div>
        </div>
      </div>
    </section>
  );
}


export default WhyOrion;
