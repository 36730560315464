import React, { Component } from "react";
import OrionLayout from '../../core/layouts/orion-layout/orion-layout';
import "./projects.scss";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllProjects: false,
      expandedIndex: -1,
    };
  }
  toggleExpand = (index) => {
    if (this.state.expandedIndex === index) {
      this.setState({ expandedIndex: -1 });
    } else {
      this.setState({ expandedIndex: index });
    }
  };

  // handleViewMoreClick = () => {
  //   this.setState((prevState) => ({
  //     showAllProjects: !prevState.showAllProjects,
  //   }));
  // };
  navigateUserToProjects = (data) => {
    const redirectedUrl = '/projects/'+ data.linkSlug;
    window.location.href = redirectedUrl
  }
  render() {
    const projects = [
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1691155677/orion-website/home-page/projects/IMG_3296_kunugn.jpg",
        title: "Male to Thilafushi Link Project - Maldives",
        // description: "1.	Assignment Name: Greater Male’ Connectivity – Male to Thilafushi Link Project in the Republic of Maldives with the loan assistance of EXIM Bank. 2.	Description of Services: Supervision Consultant of the Detailed Design Consultant. 3.	Location: Greater Male, Maldives 4.	Name of Client: AFCONS Infrastructure Ltd./ Government of Maldives 5.	Funding Authority: Exim Bank of India 6.	Project Cost: US$ 530 million 7.	Project Length: Approximately 6.70 kms comprising of approximately 3.70 kms of bridge section, and approximately 3.00 kms of at grade roads 8.	Start Date (Month/ Year): May 2022 9.	Completion Date (Month/ Year): April 2024 (Ongoing Project) 10.	Project Specifications: The Greater Male' Connectivity – Male' to Thilafushi Link Project – the “Project”, also known as the Male’ to Thilafushi Link (MTL). The Project entails the construction of a new road and bridge link between Male and Thilafushi connecting 4 islands in the Republic of Maldives. The project is a symbol of the India-Maldives ties. The Project being built under grant and concessional loan support from India. This is the largest landmark infrastructure project in Maldives - that will enhance mobility between Male’, Villingili, Gulhifalhu and Thilafushi islands, cut down logistics cost and drive people-centric economic growth- which will be a symbol of enduring friendship between the two countries.",
        linkSlug: "male-thilafushi-maldives"
      },
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1690811283/orion-website/home-page/projects/IMG_20230421_171349_l20hwt.jpg",
        title: "Green National Highways Corridor Projects (GNHCP)",
        // description: "Project Management Consultancy Services for Rehabilitation and Upgrading to 2-lanes/2-lane with paved shoulders/4 lane configuration from single/intermediate lane and strengthening of stretches of various National Highways in the states of Rajasthan, Himachal Pradesh, Uttar Pradesh and Andhra Pradesh under Green National Highways Corridor Projects (GNHCP). 2-lanes/2-lane with paved shoulders/4 lane; Project Length: 781.30 Km; Project Cost: INR 4700 Cr. Funded By: World Bank Client: Ministry of Road Transport and Highways - World Bank",
        linkSlug: "green-national-highways-corridor"
      },
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1691159744/orion-website/home-page/projects/unnamed_xxv25g.jpg",
        title: "Himachal Pradesh State Roads Transformation Project (HPSRTP)",
        // description: "Consultancy Services For 'Project Management Consultant' For Implementation of World Bank Funded HPSRTP In Himachal Pradesh. 2 Lane; Project Length: 650 KM. Project Cost: INR 830 Cr. Funded By: World Bank Client: Himachal Pradesh Road and Other Infrastructure Development Corporation Limited (HPRIDC)",
        linkSlug: "himachal-pradesh-state-roads-transformation"
      },
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1690811521/orion-website/home-page/projects/unnamed_oagfub.jpg",
        title: "Meghalaya Integrated Transport Project (MITP)",
        // description: " Consulting Services for Construction Supervision & Contract Administration in the state of Meghalaya, under Meghalaya Integrated Transport Project. 2 Lane; Project Length: 262.354KM; Project Cost: INR 625.50 Cr. Details of Major Structures: Construction of Standalone Major Bridges: Mawsmai-Shella Road connecting Balat-Shella Road (Bridge over river Umiam). (Span Length: 175.00m) RCC Bridge over River Damring at Chidaret Connecting Thapa Bazar. (Span Length: 120.0m) Construction of missing Bridge over River Umngot on Jongksha-Kharang-Dienglieng-Nongjrong-Wahiajer Road. (Span Length: 90.00m) Funded By: World Bank Client: Public Works Department (Roads), Meghalaya,   Shillong",
        linkSlug: "meghalaya-integrated-transport"
      },
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1691156010/orion-website/home-page/projects/WhatsApp_Image_2023-08-02_at_3.16.03_PM_2_pw5xhv.jpg",
        title: "Rebuild Kerala Initiative for Central Districts of the State (LOT 1)",
        // description: "Consulting Services for Authority’s Engineer for Supervision of Rehabilitation & Upgrading the State Highways & Major District Roads, having varying width, by Climatic Resilient Reconstruction, 4 roads in the Northern Districts of Mallapuram & Palakkad of Kerala State (Package -2) under Rebuild Kerala Initiative. 2 Lane; Project Length: 128.29 KM; Project Cost: INR 680 Cr. Funded By: World Bank Client: Project Management Team, Kerala State Transport Project",
        linkSlug: "rebuild-kerala-initiative-for-central-districts-1"
      },
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1691144500/orion-website/home-page/projects/unnamed_nwbvps.jpg",
        title: "Rebuild Kerala Initiative for Southern Districts of the State (LOT 2)",
        // description: "Consulting Services for Construction Supervision Climate-Resilient Reconstruction after Flooding in Kerala under Rebuild Kerala Initiative-Lot-1. 2 Lane; Project Length: 206.23KM. Project Cost: INR 872 Cr. Funded By: KFW Bank Client: Kerala State Transport Project",
        linkSlug: "rebuild-kerala-initiative-for-central-districts-2"
      },
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1691144612/orion-website/home-page/projects/0_fvjhl5.jpg",
        title: "Rebuild Kerala Initiative for Northern Districts of the State (Authority's Engineer)",
        // description: " Consulting Services for Construction Supervision Climate-Resilient Reconstruction after Flooding in Kerala under Rebuild Kerala Initiative-Lot-2. 2 Lane; Project Length: 211.848KM. Funded By: KFW Bank Client: Kerala State Transport Project",
        linkSlug: "rebuild-kerala-initiative-for-northern-districts"
      },
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1691242779/orion-website/home-page/projects/WhatsApp_Image_2023-07-24_at_4.40.50_PM-2_eznqyp.jpg",
        title: "Rajasthan Secondary Towns Development Sector Project (RSTDSP)",
        // description: "Selection of Consulting Services for Community Awareness and Public Participation (CAPP) Consultant. (Consultancy Package No. RSTDSP/CS/04). Funded By: ADB Client: Rajasthan Urban Infrastructure Development Project (RUIDP)",
        linkSlug: "rajasthan-secondary-towns-development-sector"
      },
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1691159912/orion-website/home-page/projects/rajasthan_project_xns9ds.jpg",
        title: "IE Service for Devlopment and Maintenance of Ramseen-Binmal-Raniwara Section of SH-31",
        // description: "Independent Engineer Services for Development and Maintenance of Two Lane of Ramseen-Bhinmal-Raniwara Section of SH-31 from Design (Km 0.000 to 62.619) existing (Km 43.800 to 107.600 (Total Length 62.619 Km) in the state of Rajasthan under on Hybrid Annuity Mode (WB/RSHDP-II/Annuity/04). 2 Lane; Project Length: 62.619 KM. Funded By: World Bank Client: PWD, Rajasthan",
        linkSlug: "development-of-ramseen-bhinmal-raniwara-section"
      },
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1691155884/orion-website/home-page/projects/d50c6c84-fde2-4742-b1fb-7031ad8ddf0d_tivktr.jpg",
        title: "Assam Integrated River Basin Management Project",
        // description: "Consulting Services for Project Management and Technical Consultants for Assam Integrated River Basin Management Project. Funded By: International Bank for Reconstruction and Development (IBRD), World Bank Client: Flood & River Erosion Agency of Assam (FREMAA)",
        linkSlug: "assam-integrated-river-basin-management-project"
      },
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1687846597/orion-website/home-page/projects/Approach_Road_at_Damring_Meghalaya_worpxf.jpg",
        title: "Meghalaya Ecotourism Infrastructure Development Project (MEIDP)",
        // description: "Consulting Services for Construction Supervision and Contract Administration in the State of Meghalaya under Meghalaya Ecotourism Infrastructure Development Project. (Contract Agreement Yet to sign) Funded By: New Development Bank Client: Meghalayan Age Limited",
        linkSlug: "meghalaya-ecotourism-infrastructure-development"
      },
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1691242895/orion-website/home-page/projects/unnamed_qhnbqd.jpg",
        title: "Kerala Solid Waste Management Project Package A",
        // description: "2. Technical Support Consultancy services (Package A) (Covering the districts Kollam, Pathanamthitta, Trivandrum) for Kerala Solid Waste Management Project Funded By: World Bank Client: Project Director, Kerala Solid Waste Management Project (KSWMP)",
        linkSlug: "kerala-thiruvananthapuram-kollam-pathanamthitta-a"
      },
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1691243026/orion-website/home-page/projects/WhatsApp_Image_2023-03-31_at_14.26.38_6_fds8xa.jpg",
        title: "Kerala Solid Waste Management Project Package C",
        // description: "Technical Support Consultancy services (Package C) (Covering the districts of Malappuram, Palakkad and Thrissur) for Kerala Solid Waste Management Project. Funded By: World Bank Client: Project Director, Kerala Solid Waste Management Project (KSWMP)",
        linkSlug: "kerala-thiruvananthapuram-kollam-pathanamthitta-c"
      },
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1695817971/orion-website/projects/Rjasthan%20Rural%20watersupply%20and%20mitigation/gallery-1-34_gcqpmv.png",
        title: "Rajasthan Rural Water Supply and Fluorosis Mitigation Project (Phase - II)",
        // description: "Project Management and Supervision Consultancy (PMSC) for the Rajasthan Rural Water Supply and Fluorosis Mitigation Project (Phase-II)",
        linkSlug: "rajasthan-rural-water-supply-and-fluorosis-mitigation"
      },
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1708090544/orion-website/projects/Ajmer%20railway/WhatsApp_Image_2024-02-16_at_10.34.19_AM-2_1_xiuk4s.jpg",
        title: "PSS for Gati Shakti Unit Ajmer of North Western Railway",
        // description: "Project Supervision Services(PSS) for various construction sites/ sections in the jurisdiction of Chief Project Manager, Gati Shakti Unit Ajmer of North Western Railway",
        linkSlug: "Project-Supervision-Services(PSS)-for-Gati-Shakti-Unit-Ajmer-of-North-Western-Railway"
      },
      {
        imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1708090683/orion-website/projects/FREMAA%20ADB/1707500061117_f9zyfe.jpg",
        title: "Project Implementation Support Consultant for CRBIFRERMP Project",
        // description: "Project Implementation Support Consultant (Consulting Package No.: CS-1) for Climate Resilient Brahmaputra Integrated Flood and Riverbank Erosion Risk Management Project in Assam",
        linkSlug: "Project-Implementation-Support-Consultant-for-CRBIFRERMP-Project"
      },
      {
        imageUrl:"https://res.cloudinary.com/dyihxlupl/image/upload/v1720156610/orion-website/projects/ambaji%20railway%20station%20project/3b9f3967-304e-4bd5-827f-811d92bad6b1_vlj1sh.jpg",
       title:"PMS for Development of New Ambaji Railway Station and New Tarangahill Railway Station and Construction Of 4 Lane ROBs",
       linkSlug:"PMS-for-Development-of-New-Ambaji-Railway-Station-and-New-Tarangahill-Railway-Station-and-Construction-Of-4-Lane-ROBs"
      }
    ];


    const displayedProjects = this.state.showAllProjects || this.props.comingFrom !== 'home-page'
      ? projects
      : projects;

    return (<>
        {/* Below code is when it is coming not from Home Page */}
        {this.props.comingFrom !== 'home-page' ? 
        <OrionLayout>
        <section id="projects" className="projects-component py-5">
          <div className="container">
            <h2 className="pb-3">Our Projects</h2>
            <div className="projects-grid">
              {displayedProjects.map((project, index) => (
                <div key={index} className="project">
                  <span onClick={()=>this.navigateUserToProjects(project)}>
                  <img src={project.imageUrl} alt={`Project ${index + 1}`} />
                  <h3>{project.title}</h3>
                  {this.state.expandedIndex === index ? (
                <p>{project.description}</p>
              ) : (
                <p>{project.description.slice(0, 100)}...</p>
              )} 
              </span>
              <button
                className="read-more-btn"
                onClick={() => this.toggleExpand(index)}
              >
                {this.state.expandedIndex === index ? "Read Less" : "Read More"}
              </button>
                </div>
              ))}
            </div>
          </div>
        </section>
        </OrionLayout> : 
        <section id="projects" className="projects-component py-5">
        <div className="container">
          <h2 className="pb-3">Our Projects</h2>
          <div className="projects-grid">
            {displayedProjects.map((project, index) => (
              <div key={index} className="project">
                <span onClick={()=>this.navigateUserToProjects(project)}>
                <img src={project.imageUrl} alt={`Project ${index + 1}`} />
                <h3>{project.title}</h3>
                {/* {this.state.expandedIndex === index ? (
                  <p>{project.description}</p>
                ) : (
                  <p>{project.description.slice(0, 100)}...</p>
                )} */}
                </span>
                {/* <button
                  className="read-more-btn"
                  onClick={() => this.toggleExpand(index)}
                >
              {this.state.expandedIndex === index ? "Read Less" : "Read More"}
            </button> */}
              </div>
            ))}
          </div>
          {/* {projects.length > 4 && (
            <div className="buttons-container">
              {this.state.showAllProjects ? (
                <button
                  onClick={this.handleViewMoreClick}
                  className="view-less-btn mt-5"
                >
                  View Less
                </button>
              ) : (
                <button
                  onClick={this.handleViewMoreClick}
                  className="view-more-btn mt-5"
                >
                  View More
                </button>
              )}
            </div>
          )} */}
        </div>
      </section>
        }
    </>);
  }
}

export default Projects;
