import React, { useState } from "react";
import "./news-blogs.scss";

const NewsAndBlog = () => {
  const [expandedIndex, setExpandedIndex] = useState(-1);
 
  const newsData = [
    {
      imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1691243495/orion-website/home-page/projects/1687434655081_wd93wv.jpg",
      title: "Orion Turns 3 !",
      description:
        "Time flies so fast! It has been 3 years since we laid the foundation of Orion Infra Consulting Pvt. Ltd. Every year has been a year of revival, new opportunities, unprecedented moves, and renewed hope. Today, as we commemorate three years of growth and success, we express our deepest gratitude to our clients, partners, and team members who have contributed to our journey. With every passing year, we explored the heights in infrastructure sector and spread our wings in in every sphere such as Highways, Bridges, Solid Waste Management, Water Supply, Ecotourism and River Basin Management.Total #150professionals are working out of our HO at Jaipur and other 14 ongoing project locations. We are proud to announce that Orion has reached a 135 million turnover in 2023, from 73 million in 2022.",
    },
    {
      imageUrl: "https://res.cloudinary.com/dyihxlupl/image/upload/v1691243665/orion-website/home-page/projects/1665401252709_cxatbo.jpg",
      title: "Pride of Nation Award",
      description:
        "Our Founder & CEO Mr.Sanjay Jindal was honored with the prestigious “Pride of Nation Award 2022” for Excellence in Infrastructure Consulting Services at New Delhi on 07th October 2022 by General (Dr) V K Singh Minister of Ministry of Road Transport & Highway and Civil Aviation, Govt of India",
    },
  ];


  const toggleExpand = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div className="news-and-blog-container" id="news">
      <h2>News</h2>
      <div className="news-cards-container">
        {newsData.map((item, index) => (
          <div key={index} className="news-card">
            <img src={item.imageUrl} alt={`Image for ${item.title}`} />
            <h3>{item.title}</h3>
            {expandedIndex === index ? (
              <p>{item.description}</p>
            ) : (
              <p>{item.description.slice(0, 250)}...</p>
            )}
            <button
              className="read-more-btn"
              onClick={() => toggleExpand(index)}
            >
              {expandedIndex === index ? "Read Less" : "Read More"}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsAndBlog;