import React from 'react';
import './clients.scss';
const main_client = [
    { id: 0, type: 'CENTRAL', logoUrl: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1687852202/orion-website/home-page/Clients/morth-goi_gjcd1j.png  ', name: "Ministry Of Road Transports & Highways"},
    { id: 1, type: 'STATE', logoUrl: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1687852476/orion-website/home-page/Clients/277186-273211-272667-271453-pwd_v5w8uz.avif', name: 'Public Works Department (Roads), Meghalaya' },
    { id: 2, type: 'STATE', logoUrl: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1687852476/orion-website/home-page/Clients/277186-273211-272667-271453-pwd_v5w8uz.avif', name: 'Public Works Department (Roads), Rajasthan' },
    { id: 3, type: 'STATE', logoUrl: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1687852550/orion-website/home-page/Clients/PHED_Logo_ocytql.jpg', name: 'Public Health Engineering Dept., Rajasthan' },
    { id: 4, type: 'STATE', logoUrl: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1687852685/orion-website/home-page/Clients/kstp-logo_ojxdtl.jpg', name: 'Kerala State Transport Project (KSTP), Kerala' },
    { id: 5, type: 'STATE', logoUrl: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1687852828/orion-website/home-page/Clients/download_n84ef4.png', name: 'Himachal Pradesh Road & Other Infra. Devp. Corp. Ltd.' },
    { id: 6, type: 'STATE', logoUrl: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1687852960/orion-website/home-page/Clients/download_no0upq.jpg', name: 'Rajasthan Urban Infra. Devp. Project (RUIDP)' },
    { id: 7, type: 'STATE', logoUrl: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1687853060/orion-website/home-page/Clients/images_hlti0c.png', name: 'Local Self Govt. Dept., Govt. of Kerala' },
    { id: 8, type: 'STATE', logoUrl: 'https://res.cloudinary.com/dankrz15s/image/upload/v1698573888/shah-technical-consultant-pvt-logo_mc8fbt.jpg', name: 'Shah Technical Consultant Pvt. Ltd. Mumbai' },
    { id: 9, type: 'STATE', logoUrl: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1687853392/orion-website/home-page/Clients/1676875329032_hlutha.jpg', name: 'Flood & River Erosion Management Agency of Assam' },
    // { id: 10, type: 'STATE', logoUrl: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1687852476/orion-website/home-page/Clients/277186-273211-272667-271453-pwd_v5w8uz.avif', name: 'Public Works Roads Department (PWRD), Government of Assam' },
    { id: 11, type: 'PRIVATE', logoUrl: 'https://res.cloudinary.com/dyihxlupl/image/upload/v1687853512/orion-website/home-page/Clients/unnamed_bwowyd.jpg', name: 'AFCONS Infrastructure  Ltd. Mumbai' },
];
class ClientList extends React.Component {
  render() {
    return (
      <div id="clients">
        <div className="client-list pb-5">
          <div className="container">
          <h2 className="w-100 text-center mt-4 pb-3">Clients</h2>
            <div className="row m-0">
            {main_client.map((client, index) => 
            {return(
              <div className="col-md-2 py-2" key={index}>
                    <div className="card h-100">
                      <img className="card-img-top" src={client.logoUrl} alt="Card image cap" />
                      <div className="card-body">
                        <p className="card-text">{client.name}</p>
                      </div>
                    </div>
                </div>
                )})}
                </div>
        </div>
        </div>
      </div>
    );
  }
}

export default ClientList;