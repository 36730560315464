import React, { Component } from "react";
import OrionLayout from '../../core/layouts/orion-layout/orion-layout';
import "./projects-details.scss";
import {useParams} from 'react-router-dom';
import { getProjectsDetails } from '../../core/dictionary/projects-dictionary';
const projectsData = getProjectsDetails();
console.log("projectDictionary is ",  projectsData);

const ProjectDetails = () => { 
    const {projectID} = useParams();
	console.log("projectID is ", projectID);
    const displayingData = projectsData[projectID];
    return (<>
    <OrionLayout>
        <div className="project-details">
        <h1 className="title py-5">{displayingData.title}</h1>
        <div className="image-container py-5">
        <img className="image" src={displayingData.img1} alt="Image Description"/>
        <img className="image" src={displayingData.img2} alt="Image Description"/>
        <img className="image" src={displayingData.img3} alt="Image Description"/>
        </div>
        <ol className="list-container">
                {displayingData.listItems.map((item, index) => (
                    <li key={index} className="list-item"><strong>{item.split(":")[0]}:</strong> {item.split(":")[1]}</li>
                ))}
            </ol>
        </div>
    </OrionLayout>
    </>);
}
export default ProjectDetails;