import React, { Component } from 'react';
import './header.scss';

class Header extends Component {
  render() {
    return (
      <div className="header d-lg-flex">
        <a className="company-log-container" href="/">
        <img className="logo" src="https://res.cloudinary.com/djxhh02io/image/upload/v1685101133/Logo_ixgc53.png" alt="Your logo alt text" />
        <span className="company-tagline">Re-imagining Infrastructure</span>
        </a>
        <nav className="navigation">
          
         {window.location.href.includes("projects") ? 
         <ul className="nav-list m-0 px-0">
         <li className="nav-item px-2"><a href="/">About Us</a></li>
         <li className="nav-item px-2"><a href="/">Sectors</a></li>
         <li className="nav-item px-2"><a href="/">Services</a></li>
         <li className="nav-item"><a href="#" onClick={()=>window.history.go(-1)}>Projects</a></li>
         <li className="nav-item px-2"><a href="/">Clients</a></li>
         <li className="nav-item px-2"><a href="/">Why Orion</a></li>
         <li className="nav-item px-2"><a href="/">Get In Touch</a></li>
         <li className="nav-item px-2"><a href="/">News</a></li>
         <li className="nav-item px-2"><a href="/">Gallery</a></li>
       </ul>
         :
         <ul className="nav-list m-0 px-0">
         <li className="nav-item px-2"><a href="#about-home-us">About Us</a></li>
         <li className="nav-item px-2"><a href="#sectors">Sectors</a></li>
         <li className="nav-item px-2"><a href="#services">Services</a></li>
         <li className="nav-item"><a href="#projects">Projects</a></li>
         <li className="nav-item px-2"><a href="#clients">Clients</a></li>
         <li className="nav-item px-2"><a href="#why-choose-orion">Why Orion</a></li>
         <li className="nav-item px-2"><a href="#contact">Get In Touch</a></li>
         <li className="nav-item px-2"><a href="#news">News</a></li>
         <li className="nav-item px-2"><a href="#gallery">Gallery</a></li>
       </ul> }
        </nav>
      </div>
    );
  }
}

export default Header;

