import React, { Component } from 'react';
import './sectors.scss';

class Sectors extends Component {
  render() {
    return (
        <section id="services" className='service-component pb-5'>
          <div className="container">
            <div className="row m-0">
              <h2 className="w-100 text-center mt-5 pb-3">Our Services</h2>
            </div>
            
            <div className="services-grid">
              <div className="service">
                <div className='pb-2'>
                <i className="fas fa-hammer" style={{ color: '#d30000' }}></i>
                </div>
                <h3>General Engineering Consulting</h3>
                <p>We offer engineering services and knowledge to businesses in need of a particular skill set. Our skilled engineers collaborate with clients to create solutions to issues or to identify and suggest the best course of action for a particular venture. We provide expertise for tackling a particular assignment, which saves you time, effort, and money.</p>
              </div>
              <div className="service">
                <div className='pb-2'>
                <i className="fas fa-paint-roller" style={{ color: '#d30000' }}></i>
                </div>
                <h3>Project Management Services</h3>
                <p>We offer project management services, with a focus on organising, planning, and carrying out projects in accordance with the particular needs and limitations of our clients. Orion works as development and delivery partners with both public and private asset owners, assisting in guiding early thinking to bring about transformational change.</p>
              </div>
              <div className="service">
                <div className='pb-2'>
                <i className="fas fa-screwdriver" style={{ color: '#d30000' }}></i>
                </div>
                <h3>Construction Supervision</h3>
                <p>The construction management division of Orion collaborates with creative owners and developers to bring landmark projects to life all over the world. Starting from scratch with new ideas and technologies, we are on our way to becoming one of India's largest builders, working in both urban and rural areas.</p>
              </div>
              <div className="service">
                <div className='pb-2'>
                <i className="fas fa-tools" style={{ color: '#d30000' }}></i>
                </div>
                <h3>Owner’s Engineer</h3>
                <p>We provide an independent representative or advocate for any project, also known as a lender's engineer. Throughout the whole asset lifecycle, we ensure a crucial supporting position through this service that offers project management and quality assurance support. Our services assure the reliable and effective completion of challenging building projects while assisting in containing costs.</p>
              </div>
              <div className="service">
                <div className='pb-2'>
                <i className="fas fa-hammer" style={{ color: '#d30000' }}></i>
                </div>
                <h3>Independent Engineer</h3>
                <p>Clients come to Orion for help with their most difficult engineering problems, including those involving the longest bridges, the most energy-efficient structures, the deepest tunnels, the cleanest energy plants, and the most private and secure facilities.</p>
              </div>
              <div className="service">
                <div className='pb-2'>
                <i className="fas fa-paint-roller" style={{ color: '#d30000' }}></i>
                </div>
                <h3>Authority’s Engineer</h3>
                <p>On the project in the EPC model, the Authority's Engineer acts as the "Engineer of the Authority." We examine the contractor's quality control plan and give our approval to the material supplier and the building materials. We must also review the project's progress and guarantee that it is finished on schedule.</p>
              </div>
              <div className="service">
                <div className='pb-2'>
                <i className="fas fa-screwdriver" style={{ color: '#d30000' }}></i>
                </div>
                <h3>Waste Management Strategies</h3>
                <p>More and more businesses are seeking for solutions to sustainably manage the garbage they produce as a result of governments throughout the world banning single-use plastics and establishing tight regulations for handling other plastics, municipal solid waste, and e-waste. We also offer thorough information on E-Waste Management Rules and enhanced producer responsibility.</p>
              </div>
              <div className="service">
                <div className='pb-2'>
                <i className="fas fa-hammer" style={{ color: '#d30000' }}></i>
                </div>
                <h3>Water Engineering Services</h3>
                <p>Orions' mission is to guarantee that our water clients have access to locally delivered, sustainable technology for anything from flood prevention to nutrient control to desalination. Our experts work in and across the key markets to provide comprehensive solutions that protect the environment and human health.</p>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

export default Sectors;