import React, { Component } from 'react';
import './gallery.scss';

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleImages: 5,
    };
  }

  loadMoreImages = () => {
    this.setState((prevState) => ({
      visibleImages: prevState.visibleImages + 5,
    }));
  };

  render() {
    const imageUrls = [
            "https://res.cloudinary.com/djxhh02io/image/upload/v1685207460/Package_3_Road_4_Photo_1_nhkacz.jpg",
            "https://res.cloudinary.com/djxhh02io/image/upload/v1685207408/WhatsApp_Image_2023-03-31_at_2.54.36_PM_uij38i.jpg",
            "https://res.cloudinary.com/djxhh02io/image/upload/v1685207401/WhatsApp_Image_2023-03-31_at_2.52.55_PM_tikm2q.jpg",
            "https://res.cloudinary.com/djxhh02io/image/upload/v1685207385/1680347144493_wqqiac.jpg",
            "https://res.cloudinary.com/dyihxlupl/image/upload/v1693043499/orion-website/home-page/gallery/IMG-0710_m4hejl.jpg",
            "https://res.cloudinary.com/dyihxlupl/image/upload/v1693043499/orion-website/home-page/gallery/1691467452168_xcrwal.jpg",
            "https://res.cloudinary.com/dyihxlupl/image/upload/v1693043498/orion-website/home-page/gallery/WhatsApp_Image_2023-08-02_at_12.26.43_PM_vrdvoa.jpg",
            "https://res.cloudinary.com/dyihxlupl/image/upload/v1693043498/orion-website/home-page/gallery/IMG_20230802_125053_ivoge8.jpg",
            "https://res.cloudinary.com/dyihxlupl/image/upload/v1693043498/orion-website/home-page/gallery/WhatsApp_Image_2023-08-02_at_12.56.19_PM_iqg02n.jpg",
            "https://res.cloudinary.com/dyihxlupl/image/upload/v1693043497/orion-website/home-page/gallery/59667143-b906-4750-97e9-71b8f98e2244_jp6sma.jpg",
            "https://res.cloudinary.com/dyihxlupl/image/upload/v1693043497/orion-website/home-page/gallery/image_6483441_1_oihpxs.jpg",
            "https://res.cloudinary.com/dyihxlupl/image/upload/v1693043497/orion-website/home-page/gallery/image_6483441_2_lhhcyl.jpg",
            "https://res.cloudinary.com/dyihxlupl/image/upload/v1693043496/orion-website/home-page/gallery/IMG-20230802-WA0026_l3jk9s.jpg",
            "https://res.cloudinary.com/dyihxlupl/image/upload/v1693043496/orion-website/home-page/gallery/IMG-20230807-WA0025_cxsew7.jpg",
            "https://res.cloudinary.com/dyihxlupl/image/upload/v1693043496/orion-website/home-page/gallery/WhatsApp_Image_2023-08-02_at_12.23.50_PM_buzzgv.jpg",
            "https://res.cloudinary.com/dyihxlupl/image/upload/v1693043496/orion-website/home-page/gallery/IMG-20230802-WA0028_ict6du.jpg",
            "https://res.cloudinary.com/dyihxlupl/image/upload/v1693043496/orion-website/home-page/gallery/WhatsApp_Image_2023-08-02_at_12.36.01_PM_dwph1p.jpg",
            "https://res.cloudinary.com/djxhh02io/image/upload/v1696776675/PHOTO-2022-06-21-18-18-27_xolkn8.jpg",
            "https://res.cloudinary.com/djxhh02io/image/upload/v1696776933/PHOTO-2022-06-21-18-16-59_acxb6d.jpg"
    ];

    return (
      <section id="gallery" className="gallery-component py-5">
        <div className="container">
          <h2 className='pb-3'>Gallery</h2>
          <div className="gallery">
            {imageUrls.slice(0, this.state.visibleImages).map((url, index) => (
              <img key={index} src={url} alt={`Image ${index + 1}`} />
            ))}
          </div>
          {this.state.visibleImages < imageUrls.length && (
            <div className='buttons-container d-flex justify-content-center'>
            <button className="view-more-btn mt-5" onClick={this.loadMoreImages}>
              Load More
            </button>
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default Gallery;
